import React, { useEffect, useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  addMonths,
  subMonths,
  isSameMonth,
  isSameDay,
  parseISO,
} from "date-fns";
import { it } from "date-fns/locale";
import { ChevronLeft, ChevronRight } from "lucide-react";
import styles from "./Calendar.module.scss";
interface CalendarProps {
  onDateSelect?: (formattedDate: string) => void; // Formato MySQL
  selectedDateProp?: string; // Data in formato YYYY-MM-DD
}

const Calendar: React.FC<CalendarProps> = ({
  onDateSelect,
  selectedDateProp,
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (selectedDateProp) {
      const parsedDate = parseISO(selectedDateProp);
      setSelectedDate(parsedDate);
      setCurrentMonth(parsedDate); // Imposta il mese corretto
    }
  }, [selectedDateProp]);

  const startDate = startOfWeek(startOfMonth(currentMonth));
  const endDate = endOfWeek(endOfMonth(currentMonth));

  const days: Date[] = [];
  let date = startDate;

  while (date <= endDate) {
    days.push(date);
    date = addDays(date, 1);
  }

  const handlePrevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const handleNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    const formattedDate = format(date, "yyyy-MM-dd"); // Formato MySQL
    if (onDateSelect) onDateSelect(formattedDate);
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <button onClick={handlePrevMonth}>
          <ChevronLeft className={styles.icon} />
        </button>
        <h2>{format(currentMonth, "MMMM yyyy", { locale: it })}</h2>
        <button onClick={handleNextMonth}>
          <ChevronRight className={styles.icon} />
        </button>
      </div>

      <div className={styles.grid}>
        {["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"].map((day) => (
          <div key={day} className={styles.dayName}>
            {day}
          </div>
        ))}

        {days.map((day) => (
          <button
            key={day.toString()}
            onClick={() => handleDateClick(day)}
            className={`${styles.day} ${
              isSameMonth(day, currentMonth)
                ? styles.currentMonth
                : styles.otherMonth
            } ${
              selectedDate && isSameDay(day, selectedDate)
                ? styles.selected
                : ""
            }`}
          >
            {format(day, "d")}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
