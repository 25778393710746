import axios from "axios";
import { setUser } from "../store/BusinessCardStore";
import {
  changeUserTypeData,
  ContactData,
  createUserCompanyData,
  LoginData,
  PrecontactData,
  SignupData,
  updateCompanyData,
  UpdateUserPasswordData,
  uploadImageData,
} from "../global";

import axiosInstance from "./axiosConfig";
import { jwtDecode } from "jwt-decode";

const TOKEN = "business_token";
const REFRESHTOKEN = "business_refreshtoken";

class axiosHelper {
  isLoggedIn = false;
  hasToken = false;

  constructor() {
    console.log("constructor di axioshelper");

    // let currentDate = new Date();

    // const token = localStorage.getItem(TOKEN);
    // const decodedToken = token && jwtDecode(token as string);

    // if (token && decodedToken) {
    //   if (decodedToken.exp && decodedToken.exp * 1000 < currentDate.getTime()) {
    //     // console.log("il token è scaduto");
    //     this.refreshTokens();
    //   } else {
    //     // console.log("token valido");
    //     // this.tokLogin();
    //   }
    // } else {
    //   // console.log("non ho mai avuto un token");
    // }
  }

  // setAuthToken(token: string, permanently: boolean) {
  //   // console.log(token);
  //   this.hasToken = true;
  //   // axios.defaults.headers.common["token"] = token;
  //   localStorage.removeItem(TOKEN);
  //   if (permanently) {
  //     localStorage.setItem(TOKEN, token);
  //   }
  // }

  // setRefreshToken(token: string, permanently: boolean) {
  //   // console.log(token);
  //   this.hasToken = true;
  //   localStorage.removeItem(REFRESHTOKEN);
  //   // axios.defaults.headers.common["token"] = token;
  //   if (permanently) {
  //     localStorage.setItem(REFRESHTOKEN, token);
  //   }
  // }

  // tokLogin() {
  //   // console.log(axios.defaults.headers);

  //   return axiosInstance
  //     .post(
  //       `${process.env.REACT_APP_API_ENDPOINT}/user/tokenLogin`,
  //       {
  //         token: localStorage.getItem(TOKEN),
  //       },
  //       { withCredentials: true }
  //     )
  //     .then((response) => {
  //       console.log("TOK LOGIN OK");
  //       // console.log(response.data);
  //       setUser(response.data);

  //       // setUserLogged(true);
  //       // setUser(response.data);
  //       return response.data;
  //     })
  //     .catch((e) => {
  //       // this.refreshTokens();
  //       this.refreshTokens();
  //       console.log("TOK LOGIN ERROR " + e);
  //       // setUserLogged(false);
  //     });
  // }

  login(data: LoginData) {
    return axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/user/login`,
        {
          email: data.email,
          password: data.password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  // refreshTokens() {
  //   try {
  //     return axios
  //       .post(`${process.env.REACT_APP_API_ENDPOINT}/user/refreshtoken`, {
  //         refreshToken: localStorage.getItem(REFRESHTOKEN),
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         this.setAuthToken(response.data.accessToken, true);
  //         this.setRefreshToken(response.data.refreshToken, true);
  //         return response.data;
  //       })
  //       .catch((error) => {
  //         console.log("refresh error" + error);
  //       });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  signup(data: SignupData) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/user/signup`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getUser() {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/user/myUser`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  newContact(data: ContactData) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/contact`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContactForPages(page: number) {
    return axios
      .get<ContactData[]>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/page/${page}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getPrecontactForPages(page: number) {
    return axios
      .get<PrecontactData[]>(
        `${process.env.REACT_APP_API_ENDPOINT}/precontact/page/${page}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContactByUuid(uuid: string) {
    return axios
      .get<ContactData>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateContact(data: ContactData) {
    return axios
      .put<ContactData>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/basicUpdate/${data.uuid}`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteContact(uuid?: string) {
    if (uuid) {
      return axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/contact/${uuid}`, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  deletePrecontact(uuid?: string) {
    if (uuid) {
      return axiosInstance
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/precontact/${uuid}`, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  addContactImage(data: uploadImageData) {
    return axios
      .put<ContactData>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/addImage/${data.uuid}`,
        data,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addContactLogo(data: uploadImageData) {
    return axios
      .put<ContactData>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/addLogo/${data.uuid}`,
        data,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createContactFromPrecontact(uuid: string) {
    return axiosInstance
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/precontact/becomeContact/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createNewEmptyContact() {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/gestionale/newEmpty`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  createNewEmptyCompanyContact(company_uuid: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/gestionale/newBusinessEmpty/${company_uuid}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getCompanies(page: number) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/list/${page}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  addCompanyLogo(image: uploadImageData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/addLogo/${uuid}`,
        image,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  removeCompanyLogo(uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/removeLogo/${uuid}`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getCompanyContacts(uuid: string, page: number) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/contacts/${uuid}/${page}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateCompanyInfo(data: updateCompanyData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/informations/${uuid}`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createCompany(data: updateCompanyData) {
    return axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/newEmpty`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createUser(data: createUserCompanyData) {
    return axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/createUser`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  searchContactBySurname(surname: string, company_uuid: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/search-surname/${company_uuid}/${surname}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  searchContactByUuid(uuid: string, company_uuid: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/search-uuid/${company_uuid}/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  changeUserType(data: changeUserTypeData) {
    return axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/userplatform/updateType`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getCompanyPlatformUsers(uuid: string, page: number) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/platformusers/${uuid}/${page}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getUserFromMail(email: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/platformuser/${email}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getCardDesignByPage(page: number) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/cardDesign/list/${page}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getCardDesignById(id: number) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/cardDesign/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  changeUserPassword(data: UpdateUserPasswordData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/company/gestionale/changeuserPwd`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new axiosHelper();
