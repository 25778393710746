import { createSelector } from "reselect";
import { IBusinessCardStore } from "./BusinessCardStore";

const getBusinessCardState = (state: IBusinessCardStore) => state;

export const getUser = createSelector(
  getBusinessCardState,
  (state) => state.user
);
export const getContacts = createSelector(
  getBusinessCardState,
  (state) => state.contacts
);
export const getCurrentContact = createSelector(
  getBusinessCardState,
  (state) => state.currentContact
);
export const getPrecontacts = createSelector(
  getBusinessCardState,
  (state) => state.precontacts
);

export const getCurrentPrecontact = createSelector(
  getBusinessCardState,
  (state) => state.currentPrecontact
);

export const getContactPageNumber = createSelector(
  getBusinessCardState,
  (state) => state.contactPageNumber
);

export const getCompanyContactsPageNumber = createSelector(
  getBusinessCardState,
  (state) => state.companyContactsPageNumber
);

export const getCompanyUserPageNumber = createSelector(
  getBusinessCardState,
  (state) => state.companyUsersPageNumber
);

export const getCompanies = createSelector(
  getBusinessCardState,
  (state) => state.companies
);

export const getCompany = createSelector(
  getBusinessCardState,
  (state) => state.currentCompany
);

export const getCompanyContacts = createSelector(
  getBusinessCardState,
  (state) => state.companyContacts
);

export const getCompanyUsers = createSelector(
  getBusinessCardState,
  (state) => state.companyUsers
);

export const getCompanyUser = createSelector(
  getBusinessCardState,
  (state) => state.currentUser
);

export const getCardDesigns = createSelector(
  getBusinessCardState,
  (state) => state.cardDesigns
);

export const getCurrentCardDesign = createSelector(
  getBusinessCardState,
  (state) => state.currentCardDesign
);

export const getCardDesignPageNumber = createSelector(
  getBusinessCardState,
  (state) => state.cardDesignsPageNumber
);
