import { Store } from "pullstate";
import {
  CardDesign,
  Company,
  CompanyContact,
  CompanyUser,
  ContactData,
  PrecontactData,
  User,
} from "../global";

export interface IBusinessCardStore {
  user: User;
  currentContact: ContactData;
  contacts: ContactData[];
  precontacts: PrecontactData[];
  currentPrecontact: PrecontactData;
  contactPageNumber: number;
  companiesPageNumber: number;
  companies: Company[];
  currentCompany: Company;
  companyContacts: CompanyContact[];
  companyContactsPageNumber: number;
  companyUsers: CompanyUser[];
  companyUsersPageNumber: number;
  currentUser: CompanyUser;
  cardDesigns: CardDesign[];
  currentCardDesign: CardDesign;
  cardDesignsPageNumber: number;
}

const BusinessCardStore = new Store<IBusinessCardStore>({
  contactPageNumber: 0,
  companiesPageNumber: 0,
  user: {
    username: "",
    id: 0,
  },
  currentContact: {
    name: "",
    surname: "",
    email: "",
    phone: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    website: "",
    uuid: "",
    mode: "",
    logourl: "",
    imageurl: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    order_id: "",
  },
  contacts: [],
  precontacts: [],
  currentPrecontact: {
    uuid: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    website: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    mode: "",
    logourl: "",
    imageurl: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    order_id: "",
    activated: 0,
  },
  companies: [],
  currentCompany: {
    name: "",
    uuid: "",
    address: "",
    email: "",
    logourl: "",
    website: "",
    pro_end_date: "",
  },
  companyContacts: [],
  companyContactsPageNumber: 0,
  companyUsers: [],
  companyUsersPageNumber: 0,
  currentUser: {
    email: "",
    username: "",
    contact_uuid: "",
    plan: "",
    user_type: "",
    isverified: 0,
    pro_end_date: new Date(),
    creation_date: new Date(),
  },
  cardDesigns: [],
  currentCardDesign: {
    id: 0,
    email: "",
    order_id: "",
    creation_date: "",
    name: "",
    surname: "",
    logo: "",
    color: "",
    design: "",
    notes: "",
    qr_xy: "",
    name_xy: "",
    print_type: "",
    print_color: "",
    material: "",
    plan: "",
    multipleCards: 0,
    sameContact: 0,
    qr_dimensions: 0,
    name_dimensions: 0,
    logo_scale: 0,
    logo_xy: "",
    logo_original_dimensions: "",
    nameFont: "",
    companyFont: "",
    companyName: "",
    company_xy: "",
    company_dimensions: 0,
  },
  cardDesignsPageNumber: 0,
});

export default BusinessCardStore;

export const setUser = (user: User) => {
  BusinessCardStore.update((s) => {
    s.user = user;
  });
};

export const setCurrentContact = (contact: ContactData) => {
  BusinessCardStore.update((s) => {
    s.currentContact = contact;
  });
};

export const setCurrentCompany = (company: Company) => {
  BusinessCardStore.update((s) => {
    s.currentCompany = company;
  });
};

export const setContacts = (contacts: ContactData[]) => {
  BusinessCardStore.update((s) => {
    s.contacts = contacts;
  });
};

export const addContacts = (contacts: ContactData[]) => {
  BusinessCardStore.update((s) => {
    s.contacts = [...s.contacts, ...contacts];
  });
};

export const setCompanies = (companies: Company[]) => {
  BusinessCardStore.update((s) => {
    s.companies = companies;
  });
};

export const addCompanies = (companies: Company[]) => {
  BusinessCardStore.update((s) => {
    s.companies = [...s.companies, ...companies];
  });
};

export const addCompanyContacts = (contacts: CompanyContact[]) => {
  BusinessCardStore.update((s) => {
    s.companyContacts = [...s.companyContacts, ...contacts];
  });
};

export const addCompanyUsers = (users: CompanyUser[]) => {
  BusinessCardStore.update((s) => {
    s.companyUsers = [...s.companyUsers, ...users];
  });
};

export const setCurrentPrecontact = (precontact: PrecontactData) => {
  BusinessCardStore.update((s) => {
    s.currentPrecontact = precontact;
  });
};

export const setPrecontacts = (precontacts: PrecontactData[]) => {
  // console.log(precontacts);
  BusinessCardStore.update((s) => {
    s.precontacts = precontacts;
  });
};

export const setContactPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.contactPageNumber = page;
  });
};

export const setCompaniesPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.companiesPageNumber = page;
  });
};

export const setCompanyContactsPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.companyContactsPageNumber = page;
  });
};

export const setCompanyUsersPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.companyUsersPageNumber = page;
  });
};

export const updateCompanyLogo = (image: string) => {
  BusinessCardStore.update((s) => {
    s.currentCompany.logourl = image;
  });
};

export const setCompanyContacts = (contacts: CompanyContact[]) => {
  BusinessCardStore.update((s) => {
    s.companyContacts = contacts;
  });
};

export const setCompanyUsers = (users: CompanyUser[]) => {
  BusinessCardStore.update((s) => {
    s.companyUsers = users;
  });
};

export const setCurrentUser = (user: CompanyUser) => {
  BusinessCardStore.update((s) => {
    s.currentUser = user;
  });
};

export const setCardDesignsPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.cardDesignsPageNumber = page;
  });
};

export const setCardDesigns = (cardDesigns: CardDesign[]) => {
  BusinessCardStore.update((s) => {
    s.cardDesigns = cardDesigns;
  });
};

export const addCardDesigns = (designs: CardDesign[]) => {
  BusinessCardStore.update((s) => {
    s.cardDesigns = [...s.cardDesigns, ...designs];
  });
};

export const setCurrentCardDesign = (design: CardDesign) => {
  BusinessCardStore.update((s) => {
    s.currentCardDesign = design;
  });
};
