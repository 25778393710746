import React, { useEffect, useState } from "react";
import styles from "./UserList.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  addCompanies,
  addContacts,
  setCompanies,
  setCompaniesPageNumber,
  setCompanyUsers,
  setCompanyUsersPageNumber,
  setContactPageNumber,
  setContacts,
} from "../../store/BusinessCardStore";
import {
  getCompanies,
  getCompany,
  getCompanyUserPageNumber,
  getCompanyUsers,
  getContactPageNumber,
  getContacts,
  getUser,
} from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { Company, CompanyUser, ContactData } from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import CompanyRow from "../../components/CompanyRow/CompanyRow";
import UserRow from "../../components/UserRow/UserRow";

const UserList: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);

  const users = useStoreState(BusinessCardStore, getCompanyUsers);
  const company = useStoreState(BusinessCardStore, getCompany);

  const contactPageCharged = useStoreState(
    BusinessCardStore,
    getCompanyUserPageNumber
  );

  const redirect = useNavigate();

  const [page, setPage] = useState(0);

  useEffect(() => {
    getCompanyUsersForCurrentPage();
  }, [page]);

  const getCompanyUsersForCurrentPage = async () => {
    await axiosHelper
      .getCompanyPlatformUsers(company.uuid, page)
      .then((response) => {
        //   console.log(response);
        setCompanyUsers(response.users);
      });
  };

  const getNextPage = async () => {
    if (page > contactPageCharged) {
      await axiosHelper
        .getCompanyPlatformUsers(company.uuid, page)
        .then((response) => {
          if (response.lenght > 0) setCompanyUsersPageNumber(page);
          addCompanies(response);
          setPage((prevPage) => Math.min(prevPage + 1));
        });
    }
  };

  const getPrevPage = () => {
    if (page > 1) setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <Header user={user.username} page={"LISTA USERS"} />
      {!user.username && <Login />}
      {user.username && (
        <>
          <div className="d-flex flex-row align-items-center justify-content-around col-12  mt-7 ">
            <div
              className="btn-blue rounded"
              onClick={() => redirect(`/company/${company.uuid}/update`)}
            >
              <FontAwesomeIcon icon={faAngleLeft as IconProp} className="me-3" /> Update Company
            </div>
            <div
              className="btn-blue rounded"
              onClick={() => redirect("/company/createuser")}
            >
              Crea utente
            </div>
          </div>
          <div className="p-5 d-flex flex-column align-items-center justify-content-start mt-10">
            <div className="d-flex flex-row align-items-center justify-content-start col-12 sub-title mb-5">
              <div className="d-md-flex d-none flex-row col-3 ">
                Contact Uuid
              </div>
              <div className="d-flex flex-row col-md-4 col-5 ">Email</div>
              <div className="d-flex flex-row col-md-3 col-5 ">Username</div>
              <div className="d-flex flex-row col-2 ">UserType</div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center col-12">
              {users.map((item: CompanyUser) => (
                <UserRow user={item} />
              ))}
            </div>
            <div
              className={`d-flex flex-row align-items-center justify-content-end  col-12 color-black text-xxl mt-10 ${styles.pager}`}
            >
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                className="pe-3 "
                style={{ fontSize: "1.8rem" }}
                onClick={() => getPrevPage()}
              />
              <FontAwesomeIcon
                icon={faAngleRight as IconProp}
                className="ps-3 "
                style={{ fontSize: "1.8rem" }}
                onClick={() => getNextPage()}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserList;
