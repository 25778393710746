import React, { useCallback, useEffect, useState } from "react";

import styles from "./CreateUser.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore from "../../store/BusinessCardStore";
import { getCompany, getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";

import { createUserCompanyData, updateCompanyData } from "../../global";
import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const CreateUser: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);

  const params = useParams();

  const redirect = useNavigate();

  const company = useStoreState(BusinessCardStore, getCompany);

  const [userType, setUserType] = useState("U");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  const [message, setMessage] = useState("");

  const createUser = async () => {
    const data: createUserCompanyData = {
      email: email,
      password: password,
      user_type: userType,
      company: company.uuid,
      username: username,
    };
    console.log(data);

    await axiosHelper
      .createUser(data)
      .then(async (response) => {
        console.log(response);
        // redirect(`/company/users-list/${company.uuid}`);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Header user={user.username} page={"CREATE USER"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="d-flex flex-column align-items-center justify-content-center col-12  p-5">
          <div className="d-flex flex-column align-items-center justify-content-start col-xl-8 col-12 ">
            <div
              className="d-flex flex-row align-items-center justify-content-center col-3 border btn-blue rounded align-self-start"
              onClick={() => {
                redirect(`/company/users-list/${company.uuid}`);
              }}
            >
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                className="me-3"
              />{" "}
              user list
            </div>
            <div
              className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5 color-black`}
            >
              <div className="d-flex flex-row col-12 align-items-center justify-content-between">
                <span className="text-xxl fw-bold">Company Informations</span>
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-5">
                <span className="sub-title">Email </span>
                <input
                  type="text"
                  className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
                <span className="sub-title">Username </span>
                <input
                  type="text"
                  className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
                <span className="sub-title">Password </span>
                <input
                  type="text"
                  className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
                <span className="sub-title">User Type </span>
                <div className="d-flex flex-column  align-items-center justify-content-center col-12 ">
                  <span className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <input
                      type="radio"
                      checked={userType === "G"}
                      className={`text-xxl mt-3 col-1 ${styles.inputField} `}
                      onChange={(e) => setUserType("G")}
                    />
                    <span>G</span>
                  </span>
                  <span className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <input
                      type="radio"
                      checked={userType === "U"}
                      className={`text-xxl mt-3 col-1 ${styles.inputField} ps-5`}
                      onChange={(e) => setUserType("U")}
                    />
                    <span>U</span>
                  </span>
                </div>
              </div>
              <div
                className={`d-flex flex-row align-items-center justify-content-end col-12 mt-4`}
              >
                <span
                  className={`${styles.editBtn} cursor-pointer text px-5 d-flex flex-row align-items-center justify-content-center`}
                  onClick={() => createUser()}
                >
                  Create
                </span>
              </div>
            </div>
            {message && (
              <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-3 text-s">
                {message}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CreateUser;
