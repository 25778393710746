import { useNavigate } from "react-router";

import styles from "./CardDesignRow.module.scss";
import { CardDesign, Company, ContactData } from "../../global";
import {
  setCurrentCardDesign,
  setCurrentCompany,
  setCurrentContact,
} from "../../store/BusinessCardStore";
import { useEffect } from "react";
import axiosHelper from "../../helpers/axiosHelper";

interface CardDesignRowProps {
  cardDesign: CardDesign;
}

const CardDesignRow: React.FC<CardDesignRowProps> = (props) => {
  const redirect = useNavigate();

  const getCardDesign = async () => {
    console.log("click ");
    await axiosHelper
      .getCardDesignById(props.cardDesign.id)
      .then((response) => {
        setCurrentCardDesign(response.design);
        redirect(`/cardDesign/${response.design.id}`);
      });
  };

  return (
    <>
      <div
        className={`col-12 d-flex flex-row align-items-center justify-content-between p-3 border mt-3 `}
        style={{ borderRadius: "10px", height: "90px", cursor: "pointer" }}
        onClick={() => {
          getCardDesign();
        }}
      >
        <div className="col-3 d-flex  flex-row align-items-center justify-content-center h-100 ">
          {props.cardDesign.id}
        </div>
        <div className="col-3 text-s text-start">{props.cardDesign.email}</div>
        <div className="col-3 text-s text-start">
          {props.cardDesign.order_id}
        </div>
      </div>
    </>
  );
};

export default CardDesignRow;
