import React, { useEffect, useState } from "react";
import styles from "./UserDetails.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  addCompanies,
  addContacts,
  setCompanies,
  setCompaniesPageNumber,
  setCompanyUsers,
  setCompanyUsersPageNumber,
  setContactPageNumber,
  setContacts,
  setCurrentUser,
} from "../../store/BusinessCardStore";
import {
  getCompanies,
  getCompany,
  getCompanyUser,
  getCompanyUserPageNumber,
  getCompanyUsers,
  getContactPageNumber,
  getContacts,
  getUser,
} from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import {
  changeUserTypeData,
  Company,
  CompanyUser,
  ContactData,
  UpdateUserPasswordData,
} from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import CompanyRow from "../../components/CompanyRow/CompanyRow";
import UserRow from "../../components/UserRow/UserRow";

const UserDetails: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);
  const companyuser = useStoreState(BusinessCardStore, getCompanyUser);
  const company = useStoreState(BusinessCardStore, getCompany);

  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [userType, setUserType] = useState("");

  let enddate = new Date(companyuser.pro_end_date).toLocaleDateString();
  let creationdate = new Date(companyuser.creation_date).toLocaleDateString();

  const redirect = useNavigate();

  useEffect(() => {
    setUserType(companyuser.user_type);
  }, [companyuser]);

  const updatePassword = async () => {
    const data: UpdateUserPasswordData = {
      email: companyuser.email,
      password: password,
    };
    await axiosHelper.changeUserPassword(data).then((response) => {
      setMsg(response.msg);
    });
  };

  const changeUserType = async (type: string) => {
    const data: changeUserTypeData = {
      email: companyuser.email,
      type: type,
    };
    await axiosHelper.changeUserType(data).then(async (response) => {
      console.log(response);
      await axiosHelper.getUserFromMail(companyuser.email).then((response) => {
        console.log(response);
        setCurrentUser(response.user);
      });
    });
  };

  return (
    <>
      <Header user={user.username} page={"USER DETAILS"} />
      {!user.username && <Login />}
      {user.username && (
        <>
          <div className="d-flex flex-row align-items-start justify-content-start col-12 ">
            <div
              className="d-flex flex-row align-items-center justify-content-center col-3 border btn-blue rounded align-self-start mt-5"
              onClick={() => {
                redirect(`/company/users-list/${company.uuid}`);
              }}
            >
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                className="me-3"
              />{" "}
              user list
            </div>
          </div>
          <div className="p-5 d-flex flex-row align-items-center justify-content-start mt-7 col-12">
            <div className="d-flex flex-column align-items-start justify-content-start col-6 ">
              <div className="d-flex flex-column align-items-center justify-content-center col-12 ">
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title">
                  email
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {companyuser.email}
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title mt-5">
                  username
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {companyuser.username}
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title mt-5">
                  contact_uuid
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {companyuser.contact_uuid}
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title mt-5">
                  creation date
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {creationdate}
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title mt-5">
                  user type
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {companyuser.user_type}
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title mt-5">
                  plan
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {companyuser.plan}
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title mt-5">
                  pro end date
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {enddate}
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start sub-title mt-5">
                  verified
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-start text-xxl mt-4">
                  {companyuser.isverified ? "true" : "false"}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-start col-6 ">
              <div className="d-flex flex-row col-12 align-items-center justify-content-center sub-title">
                Update Password
              </div>
              <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-4">
                <input
                  type="text"
                  value={password}
                  className="col-8"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-4">
                <span
                  className="col-3 btn-blue rounded"
                  onClick={() => updatePassword()}
                >
                  Update password
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-3 ">
                {msg}
              </div>

              <div className="d-flex flex-row align-items-center justify-content-center col-12 sub-title mt-10">
                Change user type
              </div>
              <div className="d-flex flex-row align-items-center justify-content-around col-5 mt-4">
                <span
                  className={` d-flex flex-row align-items-center justify-content-center ${
                    userType === "U" ? styles.filled : styles.empty
                  }`}
                  onClick={() => changeUserType("U")}
                >
                  U
                </span>
                <span
                  className={` d-flex flex-row align-items-center justify-content-center ${
                    userType === "G" ? styles.filled : styles.empty
                  }`}
                  onClick={() => changeUserType("G")}
                >
                  G
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserDetails;
