import React, { useCallback, useEffect, useState } from "react";

import styles from "./CreateCompany.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  setCompanies,
  setCompaniesPageNumber,
  setCurrentCompany,
} from "../../store/BusinessCardStore";
import { getCompany, getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";

import { updateCompanyData } from "../../global";
import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate, useParams } from "react-router";
import Calendar from "../../components/Calendar/Calendar";

const CreateCompany: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);

  const params = useParams();

  const redirect = useNavigate();

  const company = useStoreState(BusinessCardStore, getCompany);

  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [proEndDate, setProEndDate] = useState("");

  const [message, setMessage] = useState("");

  const createCompany = async () => {
    const data: updateCompanyData = {
      name: name,
      address: address,
      email: email,
      website: website,
      pro_end_date: proEndDate,
    };
    console.log(data);

    await axiosHelper
      .createCompany(data)
      .then(async (response) => {
        console.log(response);
        setCurrentCompany(response.companyUpdated);
        showMessage();
        await axiosHelper.getCompanies(0).then((response) => {
          setCompanies(response.companies);
          setCompaniesPageNumber(0);
          redirect("/CompanyList");
        });
      })
      .catch((error) => console.log(error));
  };

  const showMessage = () => {
    setMessage("Company created");
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  const handleDateSelect = (date: string) => {
    setProEndDate(date);
  };
  return (
    <>
      <Header user={user.username} page={"CREATE COMPANY"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="d-flex flex-column align-items-center justify-content-center col-12  p-5">
          <div className="d-flex flex-column align-items-center justify-content-start col-xl-8 col-12 ">
            <div
              className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5 color-black`}
            >
              <div className="d-flex flex-row col-12 align-items-center justify-content-between">
                <span className="text-xxl fw-bold">Company Informations</span>
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-5">
                <span className="sub-title">Name </span>
                <input
                  type="text"
                  className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
                <span className="sub-title">Website </span>
                <input
                  type="text"
                  className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
                <span className="sub-title">Address </span>
                <input
                  type="text"
                  className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
                <span className="sub-title">Email </span>
                <input
                  type="text"
                  className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
                <span className="sub-title">Pro end date </span>
                <span className="mt-3">
                  <Calendar onDateSelect={handleDateSelect} />
                </span>
              </div>
              <div
                className={`d-flex flex-row align-items-center justify-content-end col-12 mt-4`}
              >
                <span
                  className={`${styles.editBtn} cursor-pointer text px-5`}
                  onClick={() => createCompany()}
                >
                  Create
                </span>
              </div>
            </div>
            {message && (
              <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-3 text-s">
                {message}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCompany;
