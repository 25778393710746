import React, { useEffect, useState } from "react";
import styles from "./CardDesignList.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  addCardDesigns,
  addCompanies,
  addContacts,
  setCardDesigns,
  setCardDesignsPageNumber,
  setCompanies,
  setCompaniesPageNumber,
  setContactPageNumber,
  setContacts,
} from "../../store/BusinessCardStore";
import {
  getCardDesignPageNumber,
  getCardDesigns,
  getCompanies,
  getContactPageNumber,
  getUser,
} from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { CardDesign, Company, ContactData } from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import CompanyRow from "../../components/CompanyRow/CompanyRow";
import CardDesignRow from "../../components/CardDesignRow/CardDesignRow";

const CardDesignList: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);

  const cardDesigns = useStoreState(BusinessCardStore, getCardDesigns);

  const cardDesignPage = useStoreState(
    BusinessCardStore,
    getCardDesignPageNumber
  );

  const redirect = useNavigate();

  const [page, setPage] = useState(0);

  useEffect(() => {
    getCardDesignForCurrentPage();
  }, [page]);

  const getCardDesignForCurrentPage = async () => {
    await axiosHelper.getCardDesignByPage(page).then((response) => {
        console.log(response);
      setCardDesigns(response.designs);
    });
  };

  const getNextPage = async () => {
    if (page > cardDesignPage) {
      await axiosHelper.getCardDesignByPage(page).then((response) => {
        if (response.lenght > 0) setCardDesignsPageNumber(page);
        addCardDesigns(response);
        setPage((prevPage) => Math.min(prevPage + 1));
      });
    }
  };

  const getPrevPage = () => {
    if (page > 1) setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <Header user={user.username} page={"LISTA CARD DESIGNS"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start mt-10">
          <div className="d-flex flex-column align-items-center justify-content-center col-12">
            {cardDesigns.map((item: CardDesign) => (
              <CardDesignRow cardDesign={item} />
            ))}
          </div>
          <div
            className={`d-flex flex-row align-items-center justify-content-end  col-12 color-black text-xxl mt-10 ${styles.pager}`}
          >
            <FontAwesomeIcon
              icon={faAngleLeft as IconProp}
              className="pe-3 "
              style={{ fontSize: "1.8rem" }}
              onClick={() => getPrevPage()}
            />
            <FontAwesomeIcon
              icon={faAngleRight as IconProp}
              className="ps-3 "
              style={{ fontSize: "1.8rem" }}
              onClick={() => getNextPage()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CardDesignList;
