import React, { useState } from "react";

import styles from "./UserRow.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import {
  setCurrentContact,
  setCurrentUser,
} from "../../store/BusinessCardStore";
import { CompanyUser } from "../../global";

interface UserRowProps {
  user: CompanyUser;
}

const UserRow: React.FC<UserRowProps> = (props) => {
  const redirect = useNavigate();

  //   const goToEditContact = async () => {
  //     await axiosHelper.getContactByUuid(props.uuid).then((response) => {
  //       console.log(response);
  //       setCurrentContact(response);
  //       redirect(`/${props.uuid}/update`);
  //     });
  //   };

  const goToEditUser = async () => {
    await axiosHelper.getUserFromMail(props.user.email).then((response) => {
      console.log(response);
      setCurrentUser(response.user);
      redirect(`/company/user/${response.user.email}`);
    });
  };

  return (
    <>
      <div
        className=" d-flex flex-row align-items-center justify-content-start  col-12 color-black cursor-pointer "
        style={{ height: "50px", borderBottom: "1px solid black" }}
        onClick={() => {
          goToEditUser();
        }}
      >
        <div className="d-md-flex d-none flex-row col-3  overflow-hidden">
          {props.user.contact_uuid}
        </div>
        <div className="d-flex flex-row col-md-4 col-5  overflow-hidden">
          {props.user.email}
        </div>
        <div className="d-flex flex-row col-md-3 col-5 ">
          {props.user.username}
        </div>
        <div className="d-flex flex-row col-2 ">{props.user.user_type}</div>
      </div>
    </>
  );
};

export default UserRow;
