import React, { useEffect, useRef, useState } from "react";
import styles from "./CardDesignDetails.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  addCompanies,
  addContacts,
  setCompanies,
  setCompaniesPageNumber,
  setCompanyUsers,
  setCompanyUsersPageNumber,
  setContactPageNumber,
  setContacts,
  setCurrentUser,
} from "../../store/BusinessCardStore";
import {
  getCompanies,
  getCompany,
  getCompanyUser,
  getCompanyUserPageNumber,
  getCompanyUsers,
  getContactPageNumber,
  getContacts,
  getCurrentCardDesign,
  getUser,
} from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import {
  changeUserTypeData,
  Company,
  CompanyUser,
  ContactData,
  UpdateUserPasswordData,
} from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import CompanyRow from "../../components/CompanyRow/CompanyRow";
import UserRow from "../../components/UserRow/UserRow";

import beech from "../../assets/FaggioCard.png";
import bamboo from "../../assets/BambooCard.png";
import gold from "../../assets/gold.png";
import silver from "../../assets/silver.png";
import white from "../../assets/white.png";
import cherry from "../../assets/cherry.png";
import black from "../../assets/black.png";
import { NfcIcon } from "lucide-react";
import { QRCodeCanvas } from "qrcode.react";

type MaterialColor = {
  color: string;
  image: any;
};

const CardDesignDetails: React.FC = () => {
  const cardDesign = useStoreState(BusinessCardStore, getCurrentCardDesign);
  const user = useStoreState(BusinessCardStore, getUser);

  let creationdate = new Date(cardDesign.creation_date).toLocaleDateString();

  const cardColor: MaterialColor[] = [
    { color: "cherry", image: cherry },
    { color: "beech", image: beech },
    { color: "bamboo", image: bamboo },
    { color: "black", image: black },
    { color: "silver", image: silver },
    { color: "white", image: white },
    { color: "gold", image: gold },
  ];

  const containerRef = useRef<HTMLDivElement>(null);
  const squareRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const [targetX, setTargetX] = useState(135); // Posizione iniziale
  const [targetY, setTargetY] = useState(50); // Posizione iniziale
  const [color, setColor] = useState<MaterialColor>({
    color: "cherry",
    image: cherry,
  });
  const [designUrp, setDesignUrl] = useState<string>("null"); // Posizione iniziale
  const [targetLineX, setTargetLineX] = useState(105); // Posizione iniziale linea
  const [targetLineY, setTargetLineY] = useState(165); // Posizione iniziale linea
  const [targetLogoX, setTargetLogoX] = useState(165); // Posizione iniziale linea
  const [targetLogoY, setTargetLogoY] = useState(165); // Posizione iniziale linea
  const [targetCompanyX, setTargetCompanyX] = useState(165); // Posizione iniziale linea
  const [targetCompanyY, setTargetCompanyY] = useState(165); // Posizione iniziale linea
  const [logoOriginalDimensions, setLogoOriginalDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const redirect = useNavigate();

  useEffect(() => {
    cardColor.map((item) => {
      if (item.color === cardDesign.color) {
        setColor({ color: `${item.color}`, image: item.image });
      }
    });
  }, [cardDesign]);

  useEffect(() => {
    const array = cardDesign.name_xy.split(",").map((s) => s.trim());
    setTargetLineX(parseInt(array[0]));
    setTargetLineY(parseInt(array[1]));
    const array2 = cardDesign.qr_xy.split(",").map((s) => s.trim());
    setTargetX(parseInt(array2[0]));
    setTargetY(parseInt(array2[1]));
    const array3 = cardDesign.logo_xy.split(",").map((s) => s.trim());
    setTargetLogoX(parseInt(array3[0]));
    setTargetLogoY(parseInt(array3[1]));
    if (cardDesign.logo_original_dimensions) {
      const array4 = cardDesign.logo_original_dimensions
        .split(",")
        .map((s) => s.trim());
      setLogoOriginalDimensions({
        width: parseInt(array4[0]),
        height: parseInt(array4[1]),
      });
    }

    const array5 = cardDesign.company_xy.split(",").map((s) => s.trim());
    setTargetCompanyX(parseInt(array5[0]));
    setTargetCompanyY(parseInt(array5[1]));
  }, []);

  const downloadImage = async (urlToFetch: string, fileName: string) => {
    const response = await fetch(urlToFetch);
    const blob = await response.blob();
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    const url = URL.createObjectURL(blob);

    if (logoOriginalDimensions) {
      img.onload = () => {
        // Calcola le nuove dimensioni in base alla scala
        const width =
          (logoOriginalDimensions.width * cardDesign.logo_scale) / 100;
        const height =
          (logoOriginalDimensions.height * cardDesign.logo_scale) / 100;

        // Creazione di un canvas per ridimensionare l'immagine
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        ctx!.drawImage(img, 0, 0, width, height);

        // Converte il canvas in Blob e scarica il file automaticamente
        canvas.toBlob((scaledBlob: any) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(scaledBlob);
          a.download = fileName;
          a.click();
          URL.revokeObjectURL(a.href);
        }, "image/png");
      };
    }
  };

  const downloadDesign = async (imageUrl: string, fileName: string) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Errore nel download dell'immagine:", error);
    }
  };

  return (
    <>
      <Header user={user.username} page={"USER DETAILS"} />
      {!user.username && <Login />}
      {user.username && (
        <>
          <div className="d-flex flex-row align-items-start justify-content-start col-12  ">
            <div
              className="d-flex flex-row align-items-center justify-content-center col-3  btn-blue rounded align-self-start mt-5"
              onClick={() => {
                redirect(`/cardDesignList`);
              }}
            >
              <FontAwesomeIcon
                icon={faAngleLeft as IconProp}
                className="me-3"
              />
              Card design list
            </div>
          </div>
          <div className="p-5 d-flex flex-row align-items-center justify-content-center mt-7 col-12 ">
            <div className="d-flex flex-column  col-md-8 col-12  ">
              <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-5  ">
                <div className="d-flex flex-column col-md-12 col-12 align-items-center justify-content-center ">
                  <div className="d-flex flex-row  col-12 align-items-center justify-content-around mt-3">
                    <span
                      className={`${styles.materialBtn} ${
                        cardDesign.multipleCards === 0 &&
                        styles.materialBtnActive
                      } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    >
                      One card
                    </span>
                    <span
                      className={`${styles.materialBtn} ${
                        cardDesign.multipleCards === 1 &&
                        styles.materialBtnActive
                      } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    >
                      Multiple cards
                    </span>
                  </div>
                  {cardDesign.multipleCards === 1 && (
                    <>
                      <div className="d-flex flex-row  col-12 align-items-center justify-content-around mt-3">
                        <span
                          className={`${styles.materialBtn} ${
                            cardDesign.sameContact === 1 &&
                            styles.materialBtnActive
                          } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                        >
                          Same contacts
                        </span>
                        <span
                          className={`${styles.materialBtn} ${
                            cardDesign.sameContact === 0 &&
                            styles.materialBtnActive
                          } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                        >
                          Different contacts
                        </span>
                      </div>
                    </>
                  )}
                  <span className="sub-title mt-5">Your Plan :</span>
                  <div className="d-flex flex-row col-md-8 col-12 align-items-center justify-content-around mt-3">
                    <span
                      className={`${styles.materialBtn} ${
                        cardDesign.plan === "essential" &&
                        styles.materialBtnActive
                      } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    >
                      Essential
                    </span>
                    <span
                      className={`${styles.materialBtn} ${
                        cardDesign.plan === "custom" && styles.materialBtnActive
                      } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    >
                      Custom
                    </span>
                  </div>
                  <span className="sub-title mt-5">Card Material :</span>
                  <div className="d-flex flex-row col-12 align-items-center justify-content-around mt-3">
                    <span
                      className={`${styles.materialBtn} ${
                        cardDesign.material === "pvc" &&
                        styles.materialBtnActive
                      } d-flex flex-row align-items-center justify-content-center col-3 sub-title-esquare`}
                    >
                      PVC
                    </span>
                    <span
                      className={`${styles.materialBtn} ${
                        cardDesign.material === "wood" &&
                        styles.materialBtnActive
                      } d-flex flex-row align-items-center justify-content-center col-md-3 col-4 sub-title-esquare`}
                    >
                      WOOD
                    </span>
                    <span
                      className={`${styles.materialBtn} ${
                        cardDesign.material === "metal" &&
                        styles.materialBtnActive
                      } d-flex flex-row align-items-center justify-content-center col-md-3 col-4 sub-title-esquare`}
                    >
                      METAL
                    </span>
                  </div>
                  {cardDesign.material === "wood" && (
                    <>
                      <span className="sub-title mt-5"> Printing Type :</span>
                      <div className="d-flex flex-row col-md-11 col-12 align-items-center justify-content-around mt-3">
                        <span
                          className={`${styles.materialBtn} ${
                            cardDesign.print_type === "engraved" &&
                            styles.materialBtnActive
                          } d-flex flex-row align-items-center justify-content-center p-4 sub-title-esquare`}
                        >
                          Laser Engraving
                        </span>
                        <span
                          className={`${styles.materialBtn} ${
                            cardDesign.print_type === "printed" &&
                            styles.materialBtnActive
                          } d-flex flex-row align-items-center justify-content-center p-4 sub-title-esquare`}
                        >
                          Color Printing
                        </span>
                      </div>
                    </>
                  )}
                  <span className="sub-title mt-5"> Card Color :</span>

                  <div className="d-flex flex-row col-md-12  align-items-center justify-content-around mt-3">
                    {cardColor.map((item, index) => (
                      <div className="d-flex flex-column align-items-center justify-content-center ">
                        <span
                          key={index}
                          className={`${styles.colorBtn} ${
                            item.color === cardDesign.color &&
                            styles.colorBtnActive
                          }`}
                        >
                          <img src={item.image} />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column col-12 align-items-center justify-content-center ">
                <div className="d-flex flex-column align-items-center justify-content-center col-5 ">
                  <div
                    className={`${styles.cardContainer} d-flex flex-row align-items-center justify-content-center col-12`}
                  >
                    <span
                      ref={containerRef}
                      className={`${styles.card}   col-12`}
                    >
                      <span className={`${styles.nfcIcon}`}>
                        <NfcIcon color={cardDesign.print_color} />
                      </span>
                      <span
                        ref={squareRef}
                        // onMouseDown={() => setIsDragging(true)}
                        style={{
                          transform: `translate(${targetX}px, ${targetY}px)`,
                        }}
                        className={`${styles.qrCode}  d-flex flex-column align-items-center justify-content-center `}
                      >
                        <QRCodeCanvas
                          value={"https://dlctech.it"}
                          size={cardDesign.qr_dimensions * 1.33}
                          fgColor={cardDesign.print_color}
                          bgColor={"rgba(0,0,0,0)"}
                          className=""
                        />
                      </span>
                      <span
                        className={`fw-bold ${styles.namesurname}`}
                        // onMouseDown={() => setIsDraggingLine(true)}
                        style={{
                          color: cardDesign.print_color,
                          fontSize: `${cardDesign.name_dimensions}pt`,
                          fontFamily: cardDesign.nameFont,
                          transform: `translate(${targetLineX}px, ${targetLineY}px)`,
                        }}
                      >
                        {cardDesign.name + " " + cardDesign.surname}
                      </span>
                      <span
                        className={`fw-bold ${styles.namesurname}`}
                        // onMouseDown={() => setIsDraggingLine(true)}
                        style={{
                          color: cardDesign.print_color,
                          fontSize: `${cardDesign.company_dimensions}pt`,
                          fontFamily: cardDesign.companyFont,
                          transform: `translate(${targetCompanyX}px, ${targetCompanyY}px)`,
                        }}
                      >
                        {cardDesign.companyName}
                      </span>
                      {cardDesign.material === "metal" &&
                        logoOriginalDimensions && (
                          <span
                            className={`${styles.namesurname}`}
                            style={{
                              maxWidth: `100%`,
                              width:
                                (logoOriginalDimensions.width *
                                  cardDesign.logo_scale) /
                                100,
                              height:
                                (logoOriginalDimensions.height *
                                  cardDesign.logo_scale) /
                                100,
                              fontFamily: "helvetica",
                              transform: `translate(${targetLogoX}px, ${targetLogoY}px)`,
                            }}
                          >
                            <img src={cardDesign.logo} alt="Preview" />
                          </span>
                        )}
                      {cardDesign.design && <img src={cardDesign.design} />}
                    </span>
                  </div>
                </div>
              </div>

              <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12  ">
                  Order Id * :
                </div>
                <div className="col-12 col-md-12 mt-3">
                  <input
                    type="text"
                    placeholder="order id"
                    disabled
                    value={cardDesign.order_id}
                    className={`text-center w-100 sub-title ${styles.inputText}`}
                  />
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Name :
                </div>
                <div className="col-12 col-md-12 mt-3">
                  <input
                    type="text"
                    disabled
                    value={cardDesign.name}
                    className={`text-center w-100 sub-title ${styles.inputText}`}
                  />
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Surname :
                </div>
                <div className="col-12 col-md-12 mt-3">
                  <input
                    type="text"
                    disabled
                    value={cardDesign.surname}
                    className={`text-center w-100 sub-title ${styles.inputText}`}
                  />
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Company :
                </div>
                <div className="col-12 col-md-12 mt-3">
                  <input
                    type="text"
                    disabled
                    value={cardDesign.companyName}
                    className={`text-center w-100 sub-title ${styles.inputText}`}
                  />
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Font size name + surname
                </div>
                <div className="col-12 col-md-12 mt-3 text-center sub-title">
                  {cardDesign.name_dimensions} pt
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Font size company name
                </div>
                <div className="col-12 col-md-12 mt-3 text-center sub-title">
                  {cardDesign.company_dimensions} pt
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  QR size
                </div>
                <div className="col-12 col-md-12 mt-3 text-center sub-title">
                  {cardDesign.qr_dimensions} pt
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Font Name + Surname
                </div>
                <div className="col-12 col-md-12 mt-3 text-center sub-title">
                  {cardDesign.nameFont}
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Font company
                </div>
                <div className="col-12 col-md-12 mt-3 text-center sub-title">
                  {cardDesign.companyFont}
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Print Color
                </div>
                <div className="col-12 col-md-12 mt-3 text-center sub-title">
                  {cardDesign.print_color}
                </div>
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12 mt-5 ">
                  Order Email * :
                </div>
                <div className="col-12 col-md-12 mt-3 ">
                  <input
                    type="email"
                    value={cardDesign.email}
                    className={`text-center w-100 sub-title ${styles.inputText}`}
                  />
                </div>
              </div>
              <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
                {cardDesign.plan === "custom" &&
                  cardDesign.material !== "metal" && (
                    <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12  mt-5 ">
                      Logo / Front Design :
                    </div>
                  )}
              </div>

              <div
                className={`col-12 mb-7 ${
                  cardDesign.logo &&
                  cardDesign.plan === "custom" &&
                  cardDesign.material !== "metal"
                    ? "d-flex flex-row align-items-center justify-content-center"
                    : "d-none"
                }`}
              >
                <div
                  className={`col-12 col-md-6   d-flex flex-column  align-items-center justify-content-center `}
                >
                  {cardDesign.logo && (
                    <>
                      <div className=" mt-4 d-flex col-12 flex-column align-items-center justify-content-center">
                        <div className="d-flex flex-row align-items-center justify-content-around col-12 ">
                          <div className={`${styles.previewImg} col-6 `}>
                            <img
                              alt="contactImage"
                              src={cardDesign.logo}
                              //   className={`${styles.previewImg}`}
                            />
                          </div>
                          <div className={` col-1  `}>
                            <FontAwesomeIcon
                              icon={faDownload as IconProp}
                              fontSize={"3rem"}
                              color="black"
                              onClick={() =>
                                downloadDesign(
                                  cardDesign.logo,
                                  `ordine${cardDesign.order_id}_logo`
                                )
                              }
                            />
                          </div>
                        </div>

                        <div
                          className={`${styles.cardContainer} d-flex flex-row align-items-center justify-content-center col-12`}
                        >
                          <span className={`${styles.card}   col-12`}>
                            {cardDesign.logo && logoOriginalDimensions && (
                              <>
                                <span
                                  className={`${styles.namesurname} `}
                                  style={{
                                    maxWidth: `100%`,
                                    width:
                                      (logoOriginalDimensions.width *
                                        cardDesign.logo_scale) /
                                      100,
                                    height:
                                      (logoOriginalDimensions.height *
                                        cardDesign.logo_scale) /
                                      100,
                                    transform: `translate(${targetLogoX}px, ${targetLogoY}px)`,
                                  }}
                                >
                                  <img src={cardDesign.logo} />
                                </span>
                              </>
                            )}

                            {color.image && <img src={color.image} />}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                className={`col-12 mb-7 ${
                  cardDesign.design && cardDesign.plan === "custom"
                    ? "d-flex flex-row align-items-center justify-content-center"
                    : "d-none"
                }`}
              >
                <div
                  className={`col-12 col-md-6   d-flex flex-column  align-items-center justify-content-center `}
                >
                  {cardDesign.design && (
                    <>
                      <div className=" mt-4 d-flex col-12 flex-column align-items-center justify-content-center">
                        <div className="d-flex flex-row align-items-center justify-content-around col-12 ">
                          <div className={`${styles.previewImg} col-6 `}>
                            <img
                              alt="contactImage"
                              src={cardDesign.design}
                              //   className={`${styles.previewImg}`}
                            />
                          </div>
                          <div className={` col-1  `}>
                            <FontAwesomeIcon
                              icon={faDownload as IconProp}
                              fontSize={"3rem"}
                              color="black"
                              onClick={() =>
                                downloadDesign(
                                  cardDesign.design,
                                  `ordine${cardDesign.order_id}_design`
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="d-flex mt-4 flex-row  align-items-center justify-content-center ">
                        <span className="text-xl">{design.name}</span>
                      </div> */}
                        <div
                          className={`${styles.cardContainerLogo} d-flex flex-row align-items-center justify-content-center col-12`}
                        >
                          <span
                            className={`${styles.cardLogo} d-flex flex-row align-items-center justify-content-center col-12`}
                          >
                            <span
                              className={`${styles.logo} d-flex flex-column align-items-center justify-content-center `}
                            >
                              <img src={cardDesign.design} />
                            </span>
                            <img src={cardDesign.color} />
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-12  ">
                  Notes :
                </div>
                <div className="col-12 col-md-12 mt-3">
                  <textarea
                    placeholder="Add notes"
                    value={cardDesign.notes}
                    disabled
                    className={`text-center w-100 sub-title ${styles.textarea}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CardDesignDetails;
